import React from 'react';

import Marquee from 'react-fast-marquee';

import {
    cls,
    Section,
    Container,
    Text,
} from '@solublestudio/soluto-design-system';
import Heading from '@ds/components/shared/Heading';

import Picture, { PictureProps } from 'Picture';
import useIsMobile from '@ds/hooks/useIsMobile';

import styles from './styles.module.scss';

export interface BandLogosSectionProps {
    title?: string;
    subtitle?: string;
    items: PictureProps[];
}

function BandLogosSection({
    title,
    subtitle,
    items = [],
}: BandLogosSectionProps) {
    const isMobile = useIsMobile();

    const play =
        (isMobile && items?.length > 2) || (!isMobile && items?.length > 4);
    return (
        <Section className={cls('pb-mega', styles.section)}>
            <Container>
                {!!title && (
                    <Heading
                        component="h3"
                        className={cls('pb-xxxl', styles.title)}>
                        {title}
                    </Heading>
                )}
                {!!subtitle && (
                    <Text
                        component="p"
                        className={cls('pb-md', styles.subtitle)}>
                        {subtitle}
                    </Text>
                )}
            </Container>
            <div className={cls(styles.marqueeWrapper)}>
                {!!items?.length && (
                    <Marquee
                        autoFill={play}
                        pauseOnHover
                        speed={50}
                        play={play}
                        className={cls(
                            styles.marquee,
                            !play && styles.marqueePaused,
                        )}>
                        {items.map((item, i) => (
                            <div
                                key={`band-logos-section-item-${
                                    item.name ?? i
                                }`}
                                className={cls(styles.item)}>
                                <Picture
                                    image={item.src}
                                    classes={{
                                        wrapper: cls(styles.imgWrapper),
                                    }}
                                    layout="responsive"
                                />
                            </div>
                        ))}
                    </Marquee>
                )}
            </div>
        </Section>
    );
}

export default BandLogosSection;
