import React from 'react';

import {
    Section,
    Row,
    Col,
    cls,
    Container,
} from '@solublestudio/soluto-design-system';

import CardResource, {
    CardResourceProps,
} from '@ds/components/shared/Cards/Resource';

import styles from './styles.module.scss';

export interface BannerResourceProps {
    resource: CardResourceProps;
    mobileDevicesOnly?: boolean;
}

const BannerResource = ({
    resource,
    mobileDevicesOnly,
}: BannerResourceProps) => {
    return (
        <Section className={cls('pb-huge', mobileDevicesOnly && styles.hidden)}>
            <Container>
                <Row>
                    <Col col={{ xs: 12, lg: 8 }} offset={{ lg: 4 }}>
                        <CardResource {...resource} />
                    </Col>
                </Row>
            </Container>
        </Section>
    );
};

export default BannerResource;
